:root {
  --primary: #0f0f18;
  --secondary: #481440;
  --tertiary: #720455;
  --light: #910a67;
  --aqua: rgb(7, 104, 126);

  --primary-complementary: #ffffc1;
  --secondary-complementary: #c1ffc1;
  --tertiary-complementary: rgb(200, 205, 205);

  --primary-shade-1: #1c1e4a;
  --primary-shade-2: #09090e;
  --primary-tint-1: #f4055d;
  --primary-tint-2: #ff009d;

  --color-python: #3572a5; /* Existing */
  --color-javascript: #ffe100; /* Existing */
  --color-html: #e34f26; /* Existing */
  --color-css: #1702ff; /* Existing */
  --color-java: #007396; /* Existing */
  --color-cplusplus: #00599c; /* New */
  --color-ruby: #ff0d00; /* Existing */
  --color-php: #4f5d95; /* Existing */
  --color-swift: #ffac45; /* Existing */
  --color-typescript: #007acc; /* Existing */

  /* Web Development */
  --color-react: #61dafb; /* Existing */
  --color-nodejs: #68a063; /* Existing */
  --color-sql: #e38d13; /* Existing */
  --color-bootstrap: #7952b3; /* New */
  --color-angular: #dd0031; /* Existing */
  --color-vuejs: #4fc08d; /* Existing */
  --color-express: #10af9d; /* New */
  --color-django: #092e20; /* New */
  --color-spring: #6db33f; /* Existing */
  --color-laravel: #960ed5; /* Existing */
  --color-rails: #783333; /* Existing */

  /* Machine Learning */
  --color-numpy: #013243; /* New */
  --color-pandas: #150458; /* New */
  --color-matplotlib: #11557c; /* New */
  --color-scikit-learn: #f7931e; /* New */
  --color-tensorflow: #ff6f00; /* New */
  --color-keras: #d00000; /* New */

  /* Databases */
  --color-mongodb: #47a248; /* New */
  --color-mysql: #00758f; /* New */
  --color-postgresql: #336791; /* New */

  /* Tools */
  --color-git: #ff7700; /* Existing */
  --color-vs-code: #007acc; /* New */
  --color-jupyter-notebook: #f37626; /* New */
  --color-postman: #ff6c37; /* New */

  /* Others */
  --color-data-structures: #c33; /* New */
  --color-algorithms: #d9a300; /* New */
  --color-restful-apis: #ff5733; /* New */
  --color-docker: #2496ed; /* New */
   --color-socketio: #b8b25f;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--tertiary-complementary);
  list-style: none;
  font-family: "Signika Negative", sans-serif;
}
html {
  /*Smooth scroll*/
  scroll-behavior: smooth;
}
p {
  line-height: 28px;
}
a {
  font-size: 1.3rem;
}
button {
  border: none;
  background-color: transparent;
}

html {
  scrollbar-width: none;
}
body {
  background-color: rgb(0, 0, 0);
  position: relative;
}
nav {
  width: 100vw;
  position: fixed;
  top: 0;
  opacity: 1;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}
nav.hide {
  transform: translateY(-100%);
  opacity: 0;
}
.navbar {
  padding: 0 40px 0 40px;
  height: 70px;
  width: 100%;
  background-color: #05e7fc0b;
  display: flex;
  justify-content: space-between;
}
.left-nav {
  height: 100%;
  a {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  img {
    width: auto;
    height: 80%;
    border-radius: 50%;
  }
  h2 {
    font-size: 2rem;
  }
}
.right-nav {
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 20px 10px;
  }
}
.navlinks {
  /* background-color: blue; */
  height: 100%;
  ul {
    display: flex;
    height: 100%;
    align-items: center;
  }
  a {
    margin-right: 18px;
    height: 100%;
    padding: 25px 8px;
  }
}

.bars {
  width: 35px;
  height: 22px;
  transform: translateY(3px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bar {
  height: 2px;
  width: 100%;
  background-color: var(--tertiary-complementary);
  transition: all 0.3s ease-in-out;
}
#bar1 {
  width: 60%;
}
#bar3 {
  transform: translateX(70%);
  width: 60%;
}
.bars.rotate {
  #bar2 {
    width: 0;
    transform: translateX(18px);
  }
  #bar1 {
    width: 100%;
    transform: translate(0, 10px) rotate(45deg);
  }
  #bar3 {
    width: 100%;
    transform: translate(0, -10px) rotate(-45deg);
    /* transform: rotate(-45deg) translate(5px, -5px); */
  }
}
#hamburger {
  display: none;
}

main {
  min-height: 300vh;
  position: relative;
}
.hero {
  padding: 40px;
  padding-top: 75px;
  background-image: url("https://uc881a3381fdc1441ef353025fe1.previews.dropboxusercontent.com/p/thumb/ACRxBkszZ83lM4saSTuR9eJaHCM4tGpkZm6NE3N-Mp6YaHJeeNMxqeo_-pCzdjZbmw6vVwakmmrqfudqhtRIpArdedNE2iD4Kk4GYnMsY0OCLzIa3CVNvsCsbM8BJ9NAH7V5GTeJBny3oucYSXvZBGko56j9KLNuuQ7TdI2I8Y8CsGH3XeeGESZLdiIGbbYT70iJnENw-JaghB5w0d_bRYg26lQARcijlp4xnwAUz-KN7ADwy0XI_EyQX6ILwfrAEKTpRuozz-wJpnNyxCBRf0hlfoNQ9FNm1AXNJ5TFaGAkLw6jWd5mKr9FbARztEBjNmOEv-JVIVs14Oan1HvawM9SdQ0h2kzvIGcjnqV5XIyuC3wj3-t6bIJ3EVtcIg5e1acOoF2WxjK6dHaRgMFGtRh5/p.jpeg?is_prewarmed=true");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  width: 100%;
}
.row {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  overflow: hidden;
}
.col-2 {
  height: 100%;
}
.colh21 {
  width: 55%;
  padding: 30px;
  padding-top: 60px;
  backdrop-filter: blur(2px);
  opacity: 1;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
  h2 {
    font-size: 4rem;
    margin-bottom: 20px;
    transition: all 1s cubic-bezier(0, -0.39, 0.73, -0.34);
    span {
      color: rgb(6, 189, 189);
      i {
        color: rgb(10, 146, 191);
        font-size: 2.8rem;
      }
    }
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    transition: all 1.2s cubic-bezier(0, -0.5, 0.96, -1);
    span {
      color: rgb(193, 7, 35);
    }
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: justify;
    transition: all 1.5s cubic-bezier(0, -0.5, 0.96, -1.5);
    span {
      color: rgb(255, 0, 38);
    }
  }
  .hbtn {
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    margin-top: 60px;
  }
  .prm-btn {
    padding: 15px 30px;
    background-color: #05f4e4;
    color: var(--primary);
    border: 2px solid var(--primary);
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background-color: #0f0f18;
      border: 2px solid #05f4e4;
      color: #05e4f4;
      transform: translate(2px, -2px);
      box-shadow: -4px 4px 2px rgb(2, 129, 110);
    }
    &:active {
      transform: translate(0, 0);
      box-shadow: none;
    }
  }
  .sec-btn {
    padding: 15px 30px;
    background-color: #0f0f18;
    font-size: 1.2rem;
    border: 2px solid #04ccc6;
    color: #05f4f4;
    cursor: pointer;
    border-radius: 12px;
    transition: all0.3s ease-in-out;
    &:active {
      scale: 0.9;
    }
  }
}
.colh21.hide {
  opacity: 0;
  background: linear-gradient(to right, rgba(107, 105, 105, 0.53), transparent);
  h2 {
    transform: translateX(-100%);
  }
  h3 {
    transform: translateX(-100%);
  }
  p {
    transform: translateX(-100%);
  }
}
.colh22 {
  width: 45%;
  height: 600px;
  position: relative;
  opacity: 1;
  scale: 1;
  transition: all 1s ease-in-out;
  overflow: hidden;
  .picture {
    position: absolute;
    left: 50%;
    width: 95%;
    height: auto;
    aspect-ratio: 1/1;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
.colh22.hide {
  opacity: 0;
  /* transform: translateX(100%); */
  scale: 0;
}

.abt-sec {
  height: 300vh;
  background-color: gray;
  position: relative;
}
.abt {
  position: relative;
  background-color: #09090e;
  height: 100vh;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .abt-wrap {
    width: 70%;
    transition: all 0.5s ease-in-out;
    h2 {
      font-size: clamp(2.5rem, 0rem + 4.4444vw, 4rem);
      text-align: center;
      margin-bottom: 3vw;
      color: rgb(6, 189, 189);
    }
    p {
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .abt-counter {
    width: 70%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5vw 0;
    transition: all 0.5s ease-in-out;
    .counter {
      padding: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      border: 2px solid var(--aqua);
      box-shadow: 2px 2px 4px rgb(145, 168, 168);
      h2 {
        font-size: clamp(2rem, 0.3333rem + 2.963vw, 3rem);
        color: var(--primary-tint-1);
      }
      p {
        font-size: clamp(1rem, -0.3333rem + 2.3704vw, 1.8rem);
        color: var(--tertiary-complementary);
      }
    }
  }
}
.abt-projects {
  width: 80%;
  padding-left: 20px;
  transition: all 0.5s ease-in-out;
  h2 {
    font-size: clamp(2.5rem, 0rem + 4.4444vw, 4rem);
    margin: 20px 0;
    text-align: center;
    color: rgb(6, 189, 189);
  }
  .abt-projc {
    display: flex;
    justify-content: space-between;
  }
  .abt-proj {
    width: 30%;
    height: 180px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 12px;
    border: 1px solid var(--aqua);
    border-radius: 25px;
    background-color: rgb(8, 27, 67);
    padding: 0 10px;
    p{
      line-height: 20px;
      margin-top: 8px;
    }
    img {
      height: 70%;
      width: 40%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.abt.abtp {
  position: absolute;
  top: 0;
  left: 0;
}
.abt.fx {
  position: fixed;
  top: 0;
  z-index: 5;
}
.abt.abtm {
  position: absolute;
  bottom: 0;
}
.progress {
  position: absolute;
  top: 150px;
  left: 8vw;
  height: 70vh;
  width: 5px;
  background-color: var(--aqua);
  border-bottom: 6px solid rgb(3, 220, 151);
  i {
    transform: translate(-150%, -50%);
  }
}
.abt-counter.hide,
.abt-projects.hide,
.abt-wrap.hide {
  opacity: 0;
  transform: translateY(100%);
}
.prgbr {
  width: 100%;
  height: 0%;
  background-color: aqua;
}

.skills {
  display: flex;
  justify-content: space-evenly;
}

.skills-wrap {
  width: 30%;
  padding: 40px;
  h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
    color: #f4055d;
  }
  .skills-list {
    width: 100%;
    box-shadow: -3px 3px 5px rgb(37, 110, 135);
    border: 1px solid rgb(9, 52, 95);
    padding: 20px;
    margin-bottom: 12px;
    h2{
      font-size: 1.5rem;
      color: #f4055d;
      margin-bottom: 8px;
    }
    .skill {
      width: 100%;
      height: 60px;
      margin-bottom: 0px;
      border-radius: 10px;
      padding-top: 10px;
      padding-left: 20px;
      h3 {
        font-size: 1rem;
        color: #a4acab;
        padding-left: 10px;
      }
      .skill-bar {
        width: 100%;
        height: 10px;
        border: 1px solid rgb(7, 104, 126);
        margin-top: 8px;
        border-radius: 10px;
        .skill-bar-fill {
          height: 100%;

          border-radius: 10px;
        }
      }
    }
  }
}
.prjctg-wrap {
  width: 70%;
  height: 100%;
  padding: 40px 0;
  h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
    color: #f4055d;
  }
}

.prj-list {
  width: 100%;
  padding: 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 12px;
}

.prj {
  width: 100%;
  border: 2px solid var(--aqua);
  border-radius: 15px;
  padding: 20px 12px;
  background-color: #0c021c;
  margin-bottom: 20px;
  box-shadow: 3px 2px 2px aqua;
  h3 {
    margin-bottom: 20px;
  }
}

.prjskl {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.progress-ring {
  width: 80px;
  height: 80px;
}

.progress-ring circle {
  stroke-width: 8;
  fill: transparent;
  r: 30;
  cx: 40;
  cy: 40;
  transition: 0.3s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-ring__background {
  stroke: #030303;
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow-wrap: break-word;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.project-gallery {
  background-color: var(--aqua);
  padding: 2rem 0;
}
.gallery {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
.gallery h2 {
  text-align: center;
  margin-bottom: 2rem;

}
.gallery-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 1rem;
}
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.gallery-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}
.gallery-item:hover img {
  transform: scale(1.1);
}
.gallery-item-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
}
.gallery-item-info h3 {
  color: var(--color-white);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.gallery-item-info p {
  color: var(--color-white);
  font-size: 1rem;
}
.gallery-item:hover .gallery-item-info {
  opacity: 1;
}

/*-----------------footer-----------------------------*/
footer {
  background-color: var(--primary-shade-2);
  border-top: 2px solid var(--aqua);
  padding: 0 40px;
}
.f1 {
  height: auto;
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .flg {
    min-width: 45%;
    text-align: center;
    img {
      height: 100px;
      border-radius: 50%;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 3rem;
    }
  }
  .fabt {
    /* background-color: blueviolet; */
    width: 45%;
    h2 {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 20px;
      color: var(--primary-tint-1);
    }
    p {
      font-size: 1.2rem;
      text-align: justify;
    }
  }
}
hr {
  border: 1px solid var(--aqua);
  margin: 20px 0;
}
.f2 {
  padding-top: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-content: space-between;
  justify-items: center;
  gap: 20px;
  .f2l {
    width: 180px;
    border-radius: 15px 0;
    h3 {
      border-top: 2px solid rgb(146, 18, 86);
      border-right: 2px solid rgb(146, 18, 86);
      border-left: 2px solid rgb(146, 18, 86);
      font-size: 2rem;
      text-align: center;
      padding: 12px 0;
      border-radius: 15px 0;
      color: rgb(197, 14, 111);
    }
    ul {
      a {
        display: block;
        padding: 10px 20px;
        text-align: center;
        background: linear-gradient(to right, var(--primary), #07a7b9);
        /* border-left: 8px solid rgb(146, 18, 86); */
        border-radius: 15px 0;
        margin-bottom: 5px;
      }
    }
  }
}
.f3 {
  padding: 20px 0;
  background: linear-gradient(to bottom, rgb(20, 6, 43), transparent);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 60px 0;
  border-top: 2px solid var(--light);
  border-bottom: 2px solid var(--light);
  h3 {
    padding: 0 40px;
    font-size: 3rem;
  }
  .social {
    display: flex;
    justify-content: center;
    gap: 20px;
    a {
      height: 60px;
      width: auto;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: 2px solid var(--light);
      /* padding: 10px; */
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      i {
        font-size: 2rem;
        color: var(--primary-tint-1);
      }
    }
    a:hover {
      background-color: var(--light);
      i {
        color: white;
      }
    }
  }
}
.f4 {
  padding: 60px 0;
  background: linear-gradient(to bottom, var(--primary) 35%, #031741);
  .f4c {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  h3 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
  }
  .f4l {
    width: 55%;
    height: fit-content;
    background: linear-gradient(120deg, red, blue, yellow, green);
    padding: 3px;
    display: flex;
    form {
      background-color: var(--primary);
      min-width: 60%;
      padding: 40px 15px;
      label {
        font-size: 1rem;
        margin-bottom: 10px;
        display: block;
      }
      input,
      textarea {
        width: 100%;
        padding: 15px 10px;
        margin-bottom: 20px;
        border: 2px solid var(--aqua);
        border-radius: 8px;
        background-color: var(--primary);
        color: var(--tertiary-complementary);
      }
      textarea {
        min-height: 60px;
        max-height: 90px;
        min-width: 100%;
        max-width: 100%;
        /*scrollbar*/
        scrollbar-width: thin;
        scrollbar-color: var(--aqua) transparent;
      }
      .f4fbtn {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      button {
        width: 40%;
        padding: 15px 0;
        background: linear-gradient(
          to right,
          var(--primary),
          var(--primary-shade-1)
        );
        border: 2px solid var(--aqua);
        border-radius: 8px;
        color: rgb(5, 188, 188);
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
    }
    .f4fim {
      height: 600px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  .f4r {
    width: 300px;
    p {
      font-size: 1.5rem;
      display: flex;
      margin-bottom: 15px;
      i {
        font-size: 1.8rem;
        color: var(--aqua);
        margin-right: 20px;
      }
      a {
        color: var(--primary-tint-1);
        font-weight: bold;
      }
    }
  }
}
.copy {
  background-color: var(--primary-shade-1);
  padding: 20px 0;
  text-align: center;
  font-size: 1.2rem;
  color: var(--tertiary-complementary);
  a {
    color: var(--primary-tint-1);
    font-weight: bold;
  }
}
