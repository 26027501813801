@media screen and (max-width: 900px) {
  #hamburger{
    display: block;
  }
  .navbar {
    position: relative;
  }
  .navlinks {
    display: block;
    position: absolute;
    top: -500%;
    opacity: 0;
    left: -100%;
    height: fit-content;
    ul{
      display: block;
      width: 100vw;
      height: 90vh;
      padding: 0 40px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
      a{
        display: block;
        width: 100%;
        margin: 8px 0;
        padding: 10px 0;
        height: auto;
        text-align: center;
        font-size: 1.8rem;
      }
    }
    transition: all 0.3s ease-in-out;
  }
  .navlinks.hide {
    opacity: 1;
    top: 60px;
    left: 0;
    ul{
      background-color: #046972;
    }
  }

  .hero{
    padding: 30px;
  }
  .row{
    display: block;
  }
  .colh21{
    width: 100%;
    h2{
      font-size: clamp(3rem, 1.8571rem + 3.8095vw, 4rem);
    }
    h3{
      font-size: clamp(1.5rem, 0.9286rem + 1.9048vw, 2rem);
    }
    p{
      font-size: clamp(1rem, 0.4286rem + 1.9048vw, 1.5rem);
    }
  }
  .colh22{
    width: 100%;
    min-height: 20vh;
    height: 300px;
    .frame,.picture{
      height: 100%;
      width: auto;
    }
  }
  .f1{
    .flg{
      display: block;
      text-align: center;
    }
  }
  .f3{
    display: block;
    text-align: center;
    margin-top: 20px;
    h3{
      margin-bottom: 30px;
    }
  
  }
  .f4{
    .f4c{
      display: block;
      .f4l{
        width: 100%;
      }
      .f4r{
        width: 100%;
        margin-top: 60px;
        padding: 0 80px;
        p{
          font-size: 1.2rem;
        }
      }
    }
  }
  .abt-sec{
    height: 250vh;
  }
  .abt{
    .abt-wrap{
      width: 100%;
      P{
        font-size: 1rem;
      
      }
    }
    .abt-counter{
      width: 100%;
      padding-left: 5%;
      .counter{
        gap: 8px;
        padding: 10px;
        h2{
          font-size: 1.5rem;
        }
        p{
          font-size: 1rem;
        }
      }
    }
    .abt-projects{
      width: 100%;
      padding-left: 12%;
      h2{
        font-size: 1.6rem;
      }
      .abt-projc{
        display: block;
        width: 100%;
        .abt-proj{
          width: 100%;
          height: 60px;
          margin-bottom: 10px;
          border-radius: 10px;
          img{
            height: 90%;
            width: auto;
            border-radius: 10px;
          }
          h3{
            font-size: 1.2rem;
          }
          p{

          }
        }
      }
    }
  }
  .progress{
    height: 50vh;
    top: 40%;
  }
  .skills{
    flex-direction: column;
    align-items: center;
    .skills-wrap{
      width: 100%;
      max-width: 700px;
      padding: 40px 20px;
    }
    .prjctg-wrap{
      width: 100%;
      padding: 40px 20px;
      .prj-list{
        padding: 0;
        grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
        gap: 12px;
        .prj{
          width: 220px;
        }
        .progress-ring {
          width: 60px;
          height: 60px;
        }
        circle {
          stroke-width: 8;
          fill: transparent;
          r: 25;
          cx: 30;
          cy: 30;
          transition: 0.3s stroke-dashoffset;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
      }

    }
  }
}

@media screen and (max-width:620px) {
  .f1{
    display: block;
    .fabt{
      width: 100%;
      margin-top: 80px;
    }
  }
  .f2{
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    .f2l{
      width: 160px;
    }
  }
}


@media screen and (max-width:480px) {
  h2{
    font-size: 1.5rem;
  }
  .navbar{
    height: 40px;
    padding: 0 15px;
    .left-nav{
      h2{
        font-size: 1.5rem;
      }
    }
    .right-nav{
      .navlinks.hide{
        top: 30px;
      }
    }
  }

  main{
    margin-top: 42px;
  }

  footer{
    padding: 0 20px;
  }
  .f1{
    .flg{
      img{
        height: 80px;
        margin-bottom: 20px;
      }
      h2{
        font-size: 2rem;
      }
    }
    .fabt{
      margin-top: 40px;
      h2{
        font-size: 2rem;
        color: var(--tertiary);
      }
      p{
        font-size: 1rem;
      }
    }
  }
  .f2{
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    .f2l{
      width: 120px;
      h3{
        font-size: 1.2rem;
      }
      ul{
        a{
        font-size: 1rem;
        text-align: left;
      }
      }
    }
  }
  .f3{
    margin-top: 40px;
    h3{
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
    .social{
      a{
        height: 35px;
        i{
          font-size: 1rem;
        }
      }
    }
  }
  .f4{
    .f4c{
      .f4l{
        display: block;
        .f4fim{
          height: 200px;
          img{
            border-radius: 50%;
          }
        }
      }
      .f4r{
        margin-top: 40px;
        padding: 0 20px;
        p{
          font-size: 1rem;
        }
      }
    }
  }
  .hero{
    padding: 0 20px;
  }
  .colh21{
    width: 100%;
    padding: 8px;
    padding-bottom: 40px;
    border: none;
    h2{
      font-size: 1.8rem;
    }
    h3{
      font-size: 1.2rem;
    }
    p{
      font-size: 1rem;
    }
    .hbtn{
      gap: 20px;
    }
    .prm-btn,.sec-btn{
      font-size: 1rem;
      padding: 10px 15px;
    }
  }
  .colh22{
    width: 100%;
    min-height: 20vh;
    height: 200px;
    margin-top: 0;
    .frame,.picture{
      height: 80%;
      width: auto;
    }
  }
  .abt-sec{
    height: 180vh;
  }
  .abt{
    padding: 30px 12px;
    .abt-wrap{
      width: 100%;
      P{
        font-size: 0.9rem;
        line-height: 22px;
      
      }
    }
    .abt-counter{
      width: 100%;
      padding-left: 0;
      .counter{
        display: block;
        padding: 10px;
        h2{
          font-size: 1.5rem;
          text-align: center;
        }
        p{
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    .abt-projects{
      .abt-projc{
        .abt-proj{
          h3{
            font-size: 1rem;
          }
          p{
            font-size: 0.8rem;
          
          }
        }
      }
    }
  }
  .progress{
    height: 30vh;
    top: 60%;
    i{
      display: none;
    }
  }
  .skills{
    .skills-wrap{
      width: 100%;
      max-width: 700px;
      padding: 40px 8px;
    }
    .prjctg-wrap{
      width: 100%;
      padding: 40px 20px;
      .prj-list{
        padding: 0;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 12px;
        .prj{
          width: 100%;
          h3{
            text-align: center;
            overflow-wrap: break-word;
          }
        }
        .prjskl{
          flex-direction: column;
        }
        .progress-ring {
          width: 60px;
          height: 60px;
        }
        .progress-ring__circle, .progress-ring__circle1, .progress-ring__circle2, .progress-ring__background {
          stroke-width: 8;
          fill: transparent;
          r: 25;
          cx: 30;
          cy: 30;
          transition: 0.3s stroke-dashoffset;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
      }

    }
  }
}